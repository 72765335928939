import React from "react"
import {Helmet} from "react-helmet"
import {Link} from "gatsby"
import Layout from "../../components/layout"
import Jumbotron from 'react-bootstrap/Jumbotron'
import Container from 'react-bootstrap/Container'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
//import Row from 'react-bootstrap/Row'
//import Col from 'react-bootstrap/Col'
import "../../styles/styles.scss"
import offerStyles from "./offer.module.scss"
import Logo from "../../components/logo"
import "intersection-observer"
import IsVisible from "react-is-visible"
import leftCorner from "../../images/decorative-corner-left.svg"
import Row from "react-bootstrap/esm/Row"
//Lightbox//

const OfferPage = () => (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <html lang="pl" />
        <title>Oferta | Pensjonat U Wierzby Pod Lasem</title>
        <meta name="description" content="Gospodarstwo agroturystyczne | Pensjonat U Wierzby Pod Lasem. Oferujemy noclegi w komfortowych pokojach z łazienkami. Latem można wynająć 5-osobowy domek holenderski. Pensjonat położony jest w malowniczej okolicy, pełnej zieleni. Duży ogród i plac zabaw dla dzieci."/>
        <link rel="canonical" href="https://www.uwierzby.pl/oferta/" />
        <link rel="preconnect" href="https://fonts.gstatic.com"/>
        <link rel="preload" href="https://fonts.googleapis.com/css2?family=Charm&family=Cormorant+SC:wght@300&family=Quicksand&display=swap" as="style"></link>
      </Helmet>
      <Layout>
        <section className={`JumbotronSection ${offerStyles.JumbotronSection___offer}`}>
          <Jumbotron fluid className={`Jumbotron`}>
            <div className={`Jumbotron_overlay`}></div>
              <div className={`Jumbotron_logo`}>
                <Logo/>
              </div>
            <Container fluid className={`Jumbotron_container`}>
              <h1 className="animate__animated animate__slideInLeft">oferta</h1>
              <h3 className={`SectionSubTitleH3 light animate__animated animate__slideInRight`}>pensjonat blisko natury</h3>
            </Container>
          </Jumbotron>
        </section>
        <section className={`BreadcrumbSection`}>
          <Breadcrumb className={`Breadcrumb`}>
              <Breadcrumb.Item className={`Breadcrumb_item`} href="../">Strona Główna</Breadcrumb.Item>
              <Breadcrumb.Item className={`Breadcrumb_item`} active>Oferta</Breadcrumb.Item>
          </Breadcrumb>
        </section>
        <section className={`MainSection`}>
        <Container fluid className={`MainSection_wrapper`}>
          <img className={`MainSection_corner___left`} src={leftCorner} alt="top left decorative corner"></img>
          <div className={`MainSection_textWrapper`}>
            <div className={`SectionTitle_wrapper`}>
              <div className={`SectionTitle_bigLetter`}><p>O</p></div>
              <h1 className={`SectionTitle`}>oferta</h1>
              <h3 className={`SectionSubTitleH3`}>pensjonat w liczbach</h3>
            </div>
            <div className={`MainSection_main gallery`}>
              <div className={`MainSection_mainText`}>
                <div className={offerStyles.roundDivs}>
                  <Row className={offerStyles.roundDivs_Row}>
                    <div className={offerStyles.roundDivs_Card}>
                      <div className={offerStyles.card_Bouble}><div></div><p>5</p></div>
                        <div className={offerStyles.card_Text}>
                          <p>Do dyspozycji Gości przygotowano <span>5 pokoi z łazienkami</span>. 1,2,3,4, 5-osobowy</p>
                          <p>Na piętrze pensjonatu usytuowany jest <span>jeden komfortowy, klimatyzowany</span> pokój z łazienką i <span>tarasem</span>, posiadający <span>osobne wyjście na zewnątrz</span>.</p>
                          <div className="moreButtonWrapper">
                            <Link className="moreButton"
                                  activeClassName="activeMoreButton"
                                  to="/pokoje/">
                              <div className="button">
                                <span>zobacz pokoje</span>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    <div className={offerStyles.roundDivs_Card}>
                      <div className={offerStyles.card_Bouble}><div></div><p>7</p></div>
                        <div className={offerStyles.card_Text}>
                          <p>Co najmniej <span>7 udogodnień</span>, m.in. każdy pokój posiada własną  <span>łazienkę</span> z prysznicem. W całym obiekcie dostępne są: bezprzewodowy  <span>internet WiFi</span> oraz <span>telewizja satelitarna</span>. Jest możliwość   przygotowania posiłków we własnym zakresie, we w pełni wyposażonej w urządzenia AGD i naczynia, <span>kuchni ogólnodostępnej</span> i korzystania z położonej na parterze w centralnym miejscu domu - <span>sali kominkowej</span>. Możliwość skorzystania z <span>pralki</span>. Można również wykupić <span>śniadania</span> po wcześniejszym uzgodnieniu.</p>
                        </div>
                      </div>
                  </Row>
                  <Row className={offerStyles.roundDivs_Row}>
                    <div className={offerStyles.roundDivs_Card}>
                      <div className={offerStyles.card_Bouble}><div></div><p>10</p></div>
                        <div className={offerStyles.card_Text}>
                          <p>Co najmniej <span>10 atrakcji</span> dla dzieci i dla dorosłych znajdą Państwo na  rozległym terenie naszej agroturystyki. Wokół pensjonatu roztacza się <span>malowniczy krajobraz</span> oraz <span>duży ogród</span> pełen ciekawie zaaranżowanej zieleni, w którym znajdują się: <span>miejsce na ognisko</span>, <span>boisko do gier sportowych</span>, <span>plac zabaw</span> dla najmłodszych (<span>z huśtawką, zjeżdżalnią, piaskownicą i trampoliną</span>), romantczyny <span>staw</span>, <span>punkt widowkowy</span>, latem rozkładany <span>basen</span>. Oraz wiele zakątków, w których można spokojnie wypocząć.</p>
                        </div>
                      </div>
                    
                    <div className={offerStyles.roundDivs_Card}>
                    <div className={offerStyles.card_Bouble}><div></div><p>4.6</p></div>
                      <div className={offerStyles.card_Text}>
                        <p>ponad 60 komentarzy w Google od zadowolonych klientów na łączną ocenę: <br/><span>4.6</span> / 5.</p>
                      </div>
                    </div>
                  </Row>
                  <Row className={offerStyles.roundDivs_Row}>
                    <div className={`${offerStyles.roundDivs_Card} ${offerStyles.roundDivs_Card___large}`}>
                      <div className={`${offerStyles.card_Bouble} ${offerStyles.card_Bouble___large}`}><div></div><p>100%</p></div>
                      <div className={offerStyles.card_Text}>
                      <p class={offerStyles.satisfaction_Par}><span>100% satysfakcji z wypoczynku</span></p>
                      </div>
                    </div>
                    <div className={offerStyles.roundDivs_Card}>
                      <div className={offerStyles.card_Bouble}><div></div><p>20</p></div>
                        <div className={offerStyles.card_Text}>
                          <p>Ponad <span>20 lat</span> doświadczenia i wielu Gości z Polski i zagranicy, a także <span>200 lat</span> historii tego miejsca.</p>
                      </div>
                    </div>
                  </Row>
                </div>
              </div>
              <div className={`SectionTitle_wrapper`}>
                <div className={`SectionTitle_bigLetter`}><p>C</p></div>
                <h2 className={`SectionSubTitleH2`}>co nas wyróżnia</h2>
                <h3 className={`SectionSubTitleH3`}>komfort i rustykalny klimat</h3>
              </div>
              <div className={`MainSection_mainText`}>
                <p>Jesteśmy sprawdzonym, godnym zaufania obiektem. Należymy do <br/><span>Polskiej Federecji Turystyki Wiejskiej</span></p>
              </div>
              <IsVisible>
                {isVisible =>
                  <div className={`MainSection_wideTxt`}>
                    <div className={`${offerStyles.leftWings} ${isVisible ? offerStyles.open : offerStyles.close}`}>
                      <div className={offerStyles.leftWing_upper}></div>
                      <div className={offerStyles.leftWing_down}></div>
                    </div>
                    <p className={`${offerStyles.shortTxt} ${isVisible ? offerStyles.open : offerStyles.close}`}>Pensjonat położony jest w bezpośrednim sąsiedztwie <span>dużego lasu</span>, obfitującego w <span>grzyby</span> i <span>jagody</span>, w którym podczas spacerów można z bliska obserwować <span>dziką przyrodę</span>.</p>
                    <div className={`${offerStyles.rightWings} ${isVisible ? offerStyles.open : offerStyles.close}`}>
                      <div className={offerStyles.rightWing_upper}></div>
                      <div className={offerStyles.rightWing_down}></div>
                    </div>
                  </div>
                }
              </IsVisible>
              <div className={`MainSection_mainText`}>
                
                <p></p>

              </div>
            </div>
          </div>
          <div className={offerStyles.parallax}>
          <div className={offerStyles.aboutText_invitation}><p>Serdecznie zapraszamy do Pensjonatu wiosną, latem, jesienią oraz zimą.<br/> Każda pora roku ma tutaj swój niepowtarzalny klimat i urok.</p></div>
          </div>
          </Container>
        </section>
      </Layout>
  </>
);

export default OfferPage
